.bussinesOwner {
    background: white;
    height: 38rem;

    border-bottom: 0.5px solid #ddd;
    position: relative;

    height: calc(100% - 359px);

    .right {
        background: white;
        height: 100%;
        width: 50%;
        position: relative;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            position: absolute;
            right: 0;
            /* height: 49vw; */
            bottom: 0;
            width: 22vw;
            z-index: 2;
            transform: (1100% 2%);
            transform: translate(44%, 0px);
        }
    }

    .left {
        height: 100%;
        width: 50%;
        background: black;
        position: relative;
        padding-left: 9.5rem;
        display: flex;
        flex-direction: column;
    
        justify-content: center;

    }

    .rightend {
        background: white;
        display: flex;
        align-items: center;
        justify-content: start;
        height: 100%;
        background: linear-gradient(to right,
                #fff 0%,
                #fff 50%,
                #000 50%,
                #000 100%);
    }

    .inner {
        max-width: 1300px;
        display: flex;
        align-items: center;
        justify-content: start;
        margin: auto;
        height: 100%;
        width: 100%;

    }

    .headers {
        font-family: Montserrat;
        font-size: 35px;
        font-weight: 500;
        line-height: 55px;
        text-align: left;
        color: #000;
    }

    .tect {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
        color: #000;
        margin-top: 1rem;
        width: 426px;



    }

    .label {
        width: 210px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 35px;
        background: #FBD10D;
        margin-top: 2rem;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }
}

.topbussiness {
    background: #FBD10D;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;


    .headertext {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 700;
        line-height: 74px;
        text-align: center;
        color: #000;
    }

    .inner {
        max-width: 1300px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
    }

    .bookSession {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 500;
        line-height: 26.82px;
        text-align: center;
        color: #000000;
        border: 0.5px solid #000000;
        width: 270px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 35px;
        cursor: pointer;
        margin-top: 1.2rem;

        &:hover {
            transform: scale(1.03);
        }

    }

    .paratext {
        font-family: Montserrat;
        font-size: 23px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        color: #000000;

    }

    .svgsdside {
        display: flex;
        align-items: center;
        gap: 12px;
        position: relative;
        -webkit-animation: linear infinite;
        -webkit-animation-name: run;
        -webkit-animation-duration: 2s;
    }

    @keyframes run {
        0% {
            left: 0;
        }

        50% {
            left: 1rem;
        }

        100% {
            left: 0;
        }
    }
}

.mainbusinnessSection {
    height: calc(100vh - 41px);
    padding-top: 37px;
}


.section3 {
    margin-top: 6rem;
    padding-bottom: 6rem;

    .rigitside {
        width: 48%;

        img {
            width: 100%;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .inner {
        max-width: 1300px;
        margin: auto;
    }

    .cardMianprototyping {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.4rem;
    }

    .texttitle {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 500;
        line-height: 55px;
        text-align: center;
        color: #000000;
    }

    .cardPrototying {
        border: 1px solid #E5E5E5;
        padding: 1.5rem;
        width: 100%;

        border-radius: 15px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

    }

    .leftside {
        width: 48%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }

    .textForcard {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 35px;
        text-align: left;
        color: #000000;

    }

    .textforcardoara {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #000000;
    }
}

.section4 {
    background: #0000000D;
    padding-bottom: 6rem;
    padding-top: 6rem;

    .rigit {
        height: 100%;
        height: 100%;
        overflow: scroll;
        width: 40%;
        border-left: 1px solid #E5E5E5;
        padding-left: 2rem;
    }

    .number {
        color: #FBD10D;
        font-family: Montserrat;
        font-size: 35px;
        font-weight: 700;
        line-height: 35px;
        text-align: left;
        margin-top: 2rem;

    }

    .paralist {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        margin-top: 1rem;
        color: #000;
    }

    .doc {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        color: #000;
        margin-top: 1rem;
    }

    .left {
        width: 50%;
        height: 100%;
        overflow: scroll;
    }

    .inner {
        max-width: 1300px;
        width: 100%;
        margin: auto;
    }

    .contentsection {
        position: relative;
        width: 100%;
        background: #fff;
        border-radius: 15px;


    }

    .labeltext {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        color: #000;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        width: 400px;
        height: 50px;
        margin-top: 1rem;
        background: #fff;
        border-radius: 5rem;
        border: 1px solid #E5E5E5;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .sidetext {
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        text-align: left;
        color: #000;
    }

    .para {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        color: #000;
        margin-top: 1.2rem;
        margin-bottom: 1rem;
        text-align: left;

    }

    .navlabel {
        padding: 0.85rem;
        background: #000;
        display: flex;
        align-items: center;
        gap: 1.3rem;
        border-radius: 5rem;
        position: absolute;
        top: -33px;
        width: 80%;
        z-index: 2;
        margin: auto;
        right: 0;
        left: 0;
    }

    .maintitle {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 600;
        line-height: 55px;
        text-align: center;
        color: #000;
        margin-bottom: 5rem;
    }

    .innerlist {
        padding: 5rem;
        height: 62vh;
        display: flex;
        align-items: start;
        justify-content: space-between;
        overflow: hidden;

    }

    .list {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        border-radius: 5rem;
        text-align: center;
        color: #fff;
        padding: 0.5rem 2rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }
}