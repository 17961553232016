.landingPage {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    position: relative;

    .left {
        width: 50%;
        height: 100vh;
        background: linear-gradient(90deg, #F05C4D 0%, #B580F2 100%);
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imagesection {
        display: flex;
        align-items: center;
        gap: 1rem;
        position: absolute;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        div {
            animation-iteration-count: infinite;
            position: absolute;
            display: flex;
            align-items: center;
            transform: translate(-50%, -50%);
            gap: 1rem;
            animation-name: run;
            animation-duration: 2s;
        }

        svg {}
    }

    @keyframes run {
        0% {

            transform: translate(-48%, -50%);

        }

        // 100% {


        //     transform: translate(-40%, -40%);

        // }
    }

    // @keyframes run {
    //     0% {
    //         right: 48%
    //     }

    //     50% {
    //         right: 50%;
    //     }

    //     100% {
    //         right: 48%
    //     }
    // }
    .label {
        position: absolute;
        left: 0;
        bottom: 3rem;
        right: 0;
        margin: auto;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        color: black;
        text-align: center;
        width: 319px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 35px;
        cursor: pointer;
        background: #FBD10D;

        &:hover {
            transform: scale(1.03);
        }

    }

    .right {
        width: 50%;
        height: 100vh;
        background: #000000;
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

@media screen and (min-width:'300px') and (max-width:'900px') {

    .landingPage {
        display: block;
    }

    .landingPage .right {
        width: 100%;
        height: 50vh;
    }

    .landingPage .label {
        position: static;
        left: 0;
        bottom: 3rem;
        right: 0;
        margin: auto;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        color: black;
        text-align: center;
        width: 309px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 35px;
        cursor: pointer;
        background: #FBD10D;
        margin-top: 3rem;
        margin-top: 3rem;
        margin-bottom: 0;

        svg {
            width: 50%;
        }
    }

    // .landingPage .imagesection {
    //     display: flex;
    //     align-items: center;
    //     gap: 0rem;
    //     position: absolute;
    //     position: absolute;
    //     top: 45%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     z-index: 1;
    //     transform: rotate(90deg);
    //     margin: auto;
    //     left: 0;
    //     right: 0;
    //     bottom: 20rem;
    //     /* height: 10rem; */
    //     width: 9rem;
    // }}
    .landingPage .imagesection {
        display: flex;
        align-items: center;
        gap: 1rem;
        position: absolute;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-49%, -53%);
        z-index: 1;
        width: 8rem;
    }

    .landingPage .imagesection div {
        animation-iteration-count: infinite;
        position: absolute;
        display: flex;
        align-items: center;
        gap: 1rem;
        animation-name: runa;
        bottom: 10px;
        animation-duration: 2s;
        transform: rotate(90deg);
    }

    @keyframes runa {
        0% {
            bottom: 3px;


        }

        // 100% {


        //     transform: translate(-40%, -40%);

        // }
    }

    .landingPage .left {
        width: 50%;
        height: 100vh;
        background: linear-gradient(90deg, #F05C4D 0%, #B580F2 100%);
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        svg {
            width: 50%;
        }
    }
}