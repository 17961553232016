.Appasdas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    background-color: #f2f2f233;

    .chatapp {
        height: 100vh;
        width: 100%;
        padding-bottom: 9.5rem;
        padding-top: 6rem;
    }

    .bottomsection {
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
    }

    .howcanihelpwith {
        height: 100vh;
        padding-top: 77px;
        width: 100%;
        position: relative;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
    }

    .heltext {
        font-size: 1.875rem;
        font-weight: 600;
        line-height: 2.25rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    .svgclass {

        margin: auto;
        margin-bottom: 4rem;
    }

    .chat {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        overflow-y: scroll;
        padding: 10px;
        width: 100%;
        max-width: 1100px;
        margin: auto;
        padding-top: 10px;
        position: relative;

    }

    .user-message {
        align-self: self-start;

        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 10px;
        max-width: 50%;
        word-wrap: break-word;
        border: 1px solid #E5E5E5;
    }

    .flextshow {
        display: flex;
        align-items: self-start;
        gap: 1rem;
        width: 48%;
    }

    .cirlec {
        background: black;
        min-width: 60px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3rem;
    }

    .bot {
        align-self: flex-start;

        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 10px;
        max-width: 100%;
        word-wrap: break-word;
        border: 1px solid #E5E5E5;
    }

    .bot-message {
        background-color: #000;
        color: white;
        align-self: flex-start;

        padding: 10px;
        border-radius: 10px;
        max-width: 100%;
        word-wrap: break-word;
    }

    .inner {
        max-width: 1100px;
        width: 100%;
        margin: auto;
    }

    .input-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    form {
        width: 100%;
        display: flex;
        gap: 1rem;
    }

    input[type="text"] {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid green;
        outline: none;
        border: 1px solid #E2E8F0;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        height: 44px;
        background: white;
        border-radius: 45px;


    }

    .textbottom {

        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        text-align: center;
        color: #718096;

        span {
            color: black;
            text-decoration: underline;
            font-weight: 700;
        }
    }

    button {

        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        min-width: 180px;
        height: 44px;
        border-radius: 45px;
        border: none;
        outline: none;
        background: #FBD10D;

        color: #000;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }


}


@media screen and (min-width:'300px') and (max-width:'900px') {

    .Appasdas .textbottom {
        color: #718096;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        text-align: center;
        display: none;
    }

    .Appasdas .mobilebuon {
        width: 13rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 15px;
        margin: auto auto 1rem !important;
        font-size: 14px !important;
    }

    .Appasdas input[type=text] {
        font-size: 13px;
        height: 40px;
    }

    .Appasdas .flextshow {
        display: flex;
        align-items: self-start;
        gap: 1rem;
        width: 80%;
    }

    .Appasdas .bottomsection {
        padding: 0 5%;
    }

    .Appasdas .svgclass {
        margin: auto;
        margin-bottom: 2rem;
        height: 106px;
        width: 284px;
    }

    .Appasdas .heltext {
        font-size: 1.375rem;

    }

    .Appasdas .howcanihelpwith {
        height: 100vh;
        padding-top: 116px;
        width: 100%;
        position: relative;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
    }

    .Appasdas form {
        display: flex;
        gap: 10px;
    }

    .Appasdas {
        padding: 0 5%;
    }

    .Appasdas .chatapp {
        height: 100vh;
        padding-bottom: 8rem;
        padding-top: 8rem;
        width: 100%;
    }

    .Appasdas button {
        background: #fbd10d;
        border: none;
        border-radius: 45px;
        color: #000;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        height: 40px;
        line-height: 16px;
        min-width: 76px;
        outline: none;
    }

}