.footerasection {
    background: black;
    padding: 80px 0;
    padding-bottom: 2rem;

    .divsection {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1.2rem;

        svg {
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .rights {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
        text-align: center;
        color: white;
        margin-top: 2rem;
    }

    .inner {
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 1.4rem;
        max-width: 1300px;
        margin: auto;
        border-bottom: 1px solid #fff;
        padding-bottom: 4rem;
    }

    .textline {
        margin-top: 1.2rem;

        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 35px;
        text-align: left;
        color: #FFFFFF;
        opacity: 0.6;
    }

    .linetext {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 35px;
        text-align: left;
        color: #FFFFFF;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }
}

@media screen and (min-width:'300px') and (max-width:'900px') {
    .footerasection .rights {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
        text-align: center;
        color: white;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .footerasection {
        padding: 50px 5%;

        //display: none;
        .inner {

            flex-direction: column;
        }

        .linetext {
            font-family: Montserrat;
            font-size: 16x !important;

        }
    }

}