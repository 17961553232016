.founderPage {
    height: 100vh;
    padding-top: 73px;

    img {
        height: calc(100vh - 73px);
    }

    .header {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 600;
        line-height: 54.86px;
        text-align: left;
        color: #000000;

    }

    .rightside {
        padding-left: 8rem;
    }

    .inner {
        max-width: 1300px;
        margin: auto;
        display: flex;
        align-items: center;
    }

    .para {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
        color: #000000;
        margin-top: 1.5rem;
    }

    .label {
        width: 364.71px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 35px;
        background: #FBD10D;

        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.38px;
        text-align: center;
        margin-top: 2rem;
        color: #000;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .innertext {
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 700;
        line-height: 30.48px;
        text-align: left;
        color: #FBD10D;
        margin-top: 1.5rem;
    }
}

@media screen and (min-width:'300px') and (max-width:'900px') {
    .founderPage {

        height: auto;
    }

    .founderPage img {
        height: 370px;
        flex: 2;
        margin: auto;
    }

    .founderPage .rightside {
        padding-left: 0;
        flex: 1;
    }

    .founderPage {
        .mobileinner {
            padding-bottom: 2rem;
            // padding: 0 5%;
            padding-top: 5rem;
        }
    }

    .founderPage .para {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin-top: 1.5rem;
        padding: 0 5%;
    }

    .founderPage .label {
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        background: #FBD10D;
        font-family: Montserrat;
        font-size: 19px;
        font-weight: 700;
        line-height: 24.38px;
        text-align: center;
        margin-top: 0rem;
        color: black;
        cursor: pointer;
    }

    .founderPage .innertext {
        font-family: Montserrat;
        font-size: 21px;
        font-weight: 700;
        line-height: 22.48px;
        text-align: center;
        color: #FBD10D;
        margin-top: 0.8rem;
    }

    .founderPage .header {
        font-family: Montserrat;
        font-size: 37px;
        font-weight: 600;
        line-height: 37.86px;
        text-align: center;
        color: #000000;
    }

    .founderPage .inner {
        max-width: 1300px;
        margin: auto;
        display: flex;
        align-items: revert;
        flex-direction: column;
        justify-content: revert;
        flex-direction: column;
        display: none;
    }

}



